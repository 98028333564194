import AOS from 'aos';
import 'magnific-popup';
import moment from 'moment';

require( 'owl.carousel' );

$( document ).ready( function () {
	AOS.init( {
		duration: 1000,
		once: true
	} );


	$( 'nav>ul>li>a:not(.external)' ).click( function () {
		const href = $( this ).attr( 'href' );
		$( 'html, body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 2000 );
	} );

	$( '#music .owl-carousel' ).owlCarousel( {
		items: 3,
		loop: true,
		nav: false,
		dots: false,
		margin: 75,
		responsive: {
			0: {
				margin: 30,
				items: 1,
				stagePadding: 70
			},
			768: {
				margin: 75,
				items: 3
			}
		}
	} );

	$.ajax( {
		url: 'https://rest.bandsintown.com/artists/7715/events?app_id=45PRESS_7715',
		method: 'GET',
		dataType: 'json',
		xhrFields: {
			withCredentials: false
		},
		error: () => {
		},
		success: data => {
			const events = $( '#events' );
			let html = '';

			if ( data.length ) {
				for ( let event of data ) {
					const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
					html += '<div data-bit-id="' + event.id + '" data-aos="fade-up">';
					html += '<div class="event-date">' + moment( event.datetime ).format( 'MMMM D YYYY' ) + '</div>';
					html += '<div class="event-location">' + event_location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';
					html += '<a href="' + event.url + '" target="_blank" class="btn btn-md">Buy Tickets</a>';
					html += '</div>';
					html += '</div>';
				}
				events.html( html );
			} else {
				events.html( '<div class="no-events">No upcoming events.</div>' );
			}
		}
	} );

	$( '#7715-follow' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( '#7715-follow' ).html( '<div class="thanks">Thanks for signing up!</div>' );
			},
			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );

	$.ajax( {
		type: 'GET',
		url: 'https://www.juicer.io/api/feeds/7715official',
		dataType: 'json',
		xhrFields: {
			withCredentials: false
		},
		success: function ( data ) {
			if ( data.posts.items.length > 0 ) {
				let i = 0;
				let html = '';
				for ( const item of data.posts.items ) {
					html += '<div>';
					html += '<a href="' + item.full_url + '" target="_blank">';
					html += '<img src="' + item.image + '" alt="' + item.external_id + '">';
					html += '</a>';
					html += '</div>';

					i ++;
					if ( i >= 6 ) {
						break;
					}
				}

				$( '#instagram' ).html( html );
			}
		},
		error: function ( err ) {
			alert( 'An error has occurred!' );
		}
	} );

} );